<template>
  <div class="addbook card">
    <div class="addbook card-header">
      Add New Book
    </div>
    <ValidationObserver ref="form">
      <form @submit.prevent="addBook" id="frmBook">
        <div class="card-body">

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>ISBN(13):</label>
                <ValidationProvider name="ISBN13" rules="isbn13|required_ifnot:@ISBN10,ISBN10" mode="eager" v-slot="{ errors }">
                  <input v-model="book.isbn_13" name="book.isbn_13" class="form-control" type="search" placeholder="Enter ISBN13"
                  @blur="lookupIsbn(book.isbn_13)" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>ISBN(10):</label>
                <ValidationProvider name="ISBN10" rules="isbn10|required_ifnot:@ISBN13,ISBN13" mode="eager" v-slot="{ errors }">
                  <input v-model="book.isbn_10" name="book.isbn_10" class="form-control" type="search" placeholder="Enter ISBN10"
                  @blur="lookupIsbn(null, book.isbn_10)" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12" v-if="checkingIsbn">
                  <span class="text-primary help-block">Looking up ISBN. Please wait.</span>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Title:</label>
                <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                  <input v-model="book.title" name="booktitle" class="form-control" type="text" placeholder="Enter Title" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12"></div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Sub Title:</label>
                <input v-model="book.subtitle" name="bookSubTitle" class="form-control" type="text" placeholder="Enter Sub Title" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Abstract:</label>
                <textarea v-model="book.abstract" name="bookAbstract" class="form-control" type="text" placeholder="Enter Abstract"></textarea>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Authors</label>
                <ValidationProvider name="Authors" rules="required" v-slot="{ errors }">
                  <input v-model="book.author" name="bookAuthor" class="form-control" type="text" placeholder="Enter Authors. Separeated by comma" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Publishers</label>
                <ValidationProvider name="Publishers" rules="required" v-slot="{ errors }">
                  <input v-model="book.publisher" name="bookPublisher" class="form-control" type="text" placeholder="Enter Publisher. Separated by comma" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Min Grade</label>
                <ValidationProvider name="MinGrade" :rules="`between:${minGrade},${maxGrade}|required`" v-slot="{ errors }">
                  <input v-model="book.min_grade" name="min_grade" class="form-control" type="number" placeholder="Minimum school grade" :min="minGrade" :max="maxGrade" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Max Grade</label>
                <ValidationProvider name="MaxGrade" :rules="`between:${minGrade},${maxGrade}|minValue:MinGrade|required`" v-slot="{ errors }">
                  <input v-model="book.max_grade" name="max_grade" class="form-control" type="number" placeholder="Maximum school grade" :min="minGrade" :max="maxGrade" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Published Year</label>
                <ValidationProvider name="Published Year" rules="required" v-slot="{ errors }">
                  <input v-model="book.published_year" name="bookPublishedDate" class="form-control" type="text" placeholder="Enter Published Year" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Edition:</label>
                <input v-model="book.edition" name="bookEdition" class="form-control" type="text" placeholder="Enter Edition" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Select Language:</label>
                <ValidationProvider name="Language" rules="required" v-slot="{ errors }">
                  <select v-model="book.language_locale" name="bookLanguage" class="form-control" placeholder="Select Language">
                    <option>Select a Language</option>
                    <option v-for="lang in AllLanguages" :key="lang.locale" :value="lang.locale">
                      {{ lang.localizedName }}
                    </option>
                  </select>
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Content Path:</label>
                <input v-model="book.content_path" name="bookContentPath" class="form-control" type="text"
                placeholder="Links to content path sepearted by comma" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Cover Images</label>
                <ValidationProvider name="Cover Images" rules="required" v-slot="{ errors }">
                  <input v-model="book.coverimage" name="bookimage" class="form-control" type="text" placeholder="Links to cover images sepearted by comma" />
                  <span class="text-danger help-block">{{ errors[0] }}</span>
                </ValidationProvider>
                <template v-if="coverImages.length">
                  <img v-for="(img, index) in coverImages" :key="index" :src="img" class="thumbnail" />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-primary" type="submit" :disabled="loading">
            <b-spinner small v-if="loading"></b-spinner>
            Add Book
          </button>
          <button class="btn btn-danger" type="reset" :disabled="loading" @click.prevent="reset">Reset</button>
          <router-link :to="{name:'list-book'}">Back to Books</router-link>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { supportedLanguages } from '@/i18n/lang';
import '@/util/validation';
import { splitByTag } from '@/util/string.helper';
import settings from '@/app.config';

export default {
  name: 'AddBook',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      AllLanguages: supportedLanguages,
      book: {},
      feedback: '',
      loading: false,
      checkingIsbn: false,
      minGrade: settings.schoolGrade.min,
      maxGrade: settings.schoolGrade.max,
    };
  },
  computed: {
    coverImages() {
      return splitByTag(this.book.coverimage);
    },
    defaultBook() {
      return { min_grade: this.minGrade, max_grade: this.maxGrade, language_locale: settings.defaultLocale };
    },
  },
  methods: {
    lookupIsbn(isbn13, isbn10) {
      const isbn = isbn13 || isbn10;
      if (!isbn || this.checkingIsbn) return;
      this.checkingIsbn = true;
      this.$store.dispatch('book/lookupIsbn', isbn).then((book) => {
        const b = Object.assign(this.book, book);
        b.author = book.author.join(',');
        b.publisher = book.publisher.join(',');
        b.coverimage = book.coverimage.join(',');
        b.content_path = book.content_path.join(',');
        b.isbn_13 = isbn13 || b.isbn_13;
        b.isbn_10 = isbn10 || b.isbn_10;
        this.book = { ...b };
        this.checkingIsbn = false;
      }).finally().catch(() => { this.checkingIsbn = false; });
    },
    addBook() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return this.$toast.error('Please check your input', 'Form Not Valid');
        }
        const newBook = { ...this.book };
        newBook.author = splitByTag(this.book.author);
        newBook.publisher = splitByTag(this.book.publisher);
        newBook.content_path = splitByTag(this.book.content_path);
        newBook.coverimage = splitByTag(this.book.coverimage);
        newBook.min_grade = +newBook.min_grade;
        newBook.max_grade = +newBook.max_grade;
        newBook.lookup = false;

        this.loading = true;
        this.$store.dispatch('book/addBook', [newBook]).then(() => {
          this.loading = false;
          const msg = 'Book Added Successfully';
          this.$toast.success(msg);
          this.feedback = msg;
          this.reset();
        }).catch(() => {
          this.$toast.error('Cannot add Book. Try again later.');
          this.loading = false;
        });

        return false;
      });
    },
    reset() {
      this.$refs.form.reset();
      this.book = { ...this.defaultBook };
    },
  },
  created() {
    this.book = Object.assign({}, this.defaultBook);
  },
};
</script>

<style lang="stylus" scoped>
.addbook
 .form-group
   margin-bottom 0.5rem
   label
    margin-bottom 0
 .btn
   margin-right 1rem
 .thumbnail
   width: 11%;
   margin-right: 0.5rem;
</style>
