import { render, staticRenderFns } from "./NewBook.vue?vue&type=template&id=2601c544&scoped=true&"
import script from "./NewBook.vue?vue&type=script&lang=js&"
export * from "./NewBook.vue?vue&type=script&lang=js&"
import style0 from "./NewBook.vue?vue&type=style&index=0&id=2601c544&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2601c544",
  null
  
)

export default component.exports