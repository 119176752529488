var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addbook card"},[_c('div',{staticClass:"addbook card-header"},[_vm._v(" Add New Book ")]),_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"id":"frmBook"},on:{"submit":function($event){$event.preventDefault();return _vm.addBook($event)}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ISBN(13):")]),_c('ValidationProvider',{attrs:{"name":"ISBN13","rules":"isbn13|required_ifnot:@ISBN10,ISBN10","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.isbn_13),expression:"book.isbn_13"}],staticClass:"form-control",attrs:{"name":"book.isbn_13","type":"search","placeholder":"Enter ISBN13"},domProps:{"value":(_vm.book.isbn_13)},on:{"blur":function($event){return _vm.lookupIsbn(_vm.book.isbn_13)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "isbn_13", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ISBN(10):")]),_c('ValidationProvider',{attrs:{"name":"ISBN10","rules":"isbn10|required_ifnot:@ISBN13,ISBN13","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.isbn_10),expression:"book.isbn_10"}],staticClass:"form-control",attrs:{"name":"book.isbn_10","type":"search","placeholder":"Enter ISBN10"},domProps:{"value":(_vm.book.isbn_10)},on:{"blur":function($event){return _vm.lookupIsbn(null, _vm.book.isbn_10)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "isbn_10", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),(_vm.checkingIsbn)?_c('div',{staticClass:"col-md-12"},[_c('span',{staticClass:"text-primary help-block"},[_vm._v("Looking up ISBN. Please wait.")])]):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Title:")]),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.title),expression:"book.title"}],staticClass:"form-control",attrs:{"name":"booktitle","type":"text","placeholder":"Enter Title"},domProps:{"value":(_vm.book.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "title", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"}),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Sub Title:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.subtitle),expression:"book.subtitle"}],staticClass:"form-control",attrs:{"name":"bookSubTitle","type":"text","placeholder":"Enter Sub Title"},domProps:{"value":(_vm.book.subtitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "subtitle", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Abstract:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.abstract),expression:"book.abstract"}],staticClass:"form-control",attrs:{"name":"bookAbstract","type":"text","placeholder":"Enter Abstract"},domProps:{"value":(_vm.book.abstract)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "abstract", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Authors")]),_c('ValidationProvider',{attrs:{"name":"Authors","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.author),expression:"book.author"}],staticClass:"form-control",attrs:{"name":"bookAuthor","type":"text","placeholder":"Enter Authors. Separeated by comma"},domProps:{"value":(_vm.book.author)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "author", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Publishers")]),_c('ValidationProvider',{attrs:{"name":"Publishers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.publisher),expression:"book.publisher"}],staticClass:"form-control",attrs:{"name":"bookPublisher","type":"text","placeholder":"Enter Publisher. Separated by comma"},domProps:{"value":(_vm.book.publisher)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "publisher", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Min Grade")]),_c('ValidationProvider',{attrs:{"name":"MinGrade","rules":("between:" + _vm.minGrade + "," + _vm.maxGrade + "|required")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.min_grade),expression:"book.min_grade"}],staticClass:"form-control",attrs:{"name":"min_grade","type":"number","placeholder":"Minimum school grade","min":_vm.minGrade,"max":_vm.maxGrade},domProps:{"value":(_vm.book.min_grade)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "min_grade", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Max Grade")]),_c('ValidationProvider',{attrs:{"name":"MaxGrade","rules":("between:" + _vm.minGrade + "," + _vm.maxGrade + "|minValue:MinGrade|required")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.max_grade),expression:"book.max_grade"}],staticClass:"form-control",attrs:{"name":"max_grade","type":"number","placeholder":"Maximum school grade","min":_vm.minGrade,"max":_vm.maxGrade},domProps:{"value":(_vm.book.max_grade)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "max_grade", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Published Year")]),_c('ValidationProvider',{attrs:{"name":"Published Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.published_year),expression:"book.published_year"}],staticClass:"form-control",attrs:{"name":"bookPublishedDate","type":"text","placeholder":"Enter Published Year"},domProps:{"value":(_vm.book.published_year)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "published_year", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Edition:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.edition),expression:"book.edition"}],staticClass:"form-control",attrs:{"name":"bookEdition","type":"text","placeholder":"Enter Edition"},domProps:{"value":(_vm.book.edition)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "edition", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Select Language:")]),_c('ValidationProvider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.language_locale),expression:"book.language_locale"}],staticClass:"form-control",attrs:{"name":"bookLanguage","placeholder":"Select Language"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.book, "language_locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',[_vm._v("Select a Language")]),_vm._l((_vm.AllLanguages),function(lang){return _c('option',{key:lang.locale,domProps:{"value":lang.locale}},[_vm._v(" "+_vm._s(lang.localizedName)+" ")])})],2),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Content Path:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.content_path),expression:"book.content_path"}],staticClass:"form-control",attrs:{"name":"bookContentPath","type":"text","placeholder":"Links to content path sepearted by comma"},domProps:{"value":(_vm.book.content_path)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "content_path", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cover Images")]),_c('ValidationProvider',{attrs:{"name":"Cover Images","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.coverimage),expression:"book.coverimage"}],staticClass:"form-control",attrs:{"name":"bookimage","type":"text","placeholder":"Links to cover images sepearted by comma"},domProps:{"value":(_vm.book.coverimage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "coverimage", $event.target.value)}}}),_c('span',{staticClass:"text-danger help-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.coverImages.length)?_vm._l((_vm.coverImages),function(img,index){return _c('img',{key:index,staticClass:"thumbnail",attrs:{"src":img}})}):_vm._e()],2)])])]),_c('div',{staticClass:"card-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Add Book ")],1),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"reset","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.reset($event)}}},[_vm._v("Reset")]),_c('router-link',{attrs:{"to":{name:'list-book'}}},[_vm._v("Back to Books")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }